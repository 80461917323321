import { L_ACTIVE_CHECK_LF_BOX, TEXT_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const tableColumns = [
    { key: 'actions', label: '', thStyle: { width: '100px' } },
    { key: 'name', label: 'Name', thStyle: { width: '300px', 'padding-left': '15px' } },
    { key: 'active', label: 'Status', thStyle: { width: '150px', 'padding-left': '15px' } },
    { key: 'default', label: 'Default', thStyle: { 'padding-left': '15px' } },
  ]

  const autoSuggestTableColumns = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'status',
      label: 'Statdus',
    },
  ]

  const assetConditionFields = {
    name: {
      type: TEXT_INPUT,
      label: 'Name',
      rules: 'required',
      placeholder: 'Type...',
    },
    active: {
      type: L_ACTIVE_CHECK_LF_BOX,
      label: 'Active',
      rules: 'required',
    },
  }

  return {
    assetConditionFields,
    tableColumns,
    autoSuggestTableColumns,
  }
}
