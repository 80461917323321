<template>
  <div v-if="!loading">
    <p class="font-weight-bolder font-medium-5">
      {{ isUpdate ? $t('Update asset condition taxonomy') : $t('Add an asset condition taxonomy') }}
    </p>
    <error-alert
      :fields="assetConditionFields"
      :error="error"
    />
    <div>
      <validation-observer
        ref="assetConditionCreateVal"
      >
        <b-row>
          <b-col
            v-for="field in ['name']"
            :key="field"
            md="6"
          >
            <component
              :is="assetConditionFields[field].type"
              v-model="assetCondition[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col
            v-for="field in ['active']"
            :key="field"
            md="6"
          >
            <component
              :is="assetConditionFields[field].type"
              v-model="assetCondition[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
        </b-row>
      </validation-observer>
    </div>
    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <div>
          <b-button
            v-if="isFormChanged"
            type="button"
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="loader"
          >
            <feather-icon
              icon="LCancelIcon"
              size="16"
            />
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            v-else
            type="button"
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="cancel"
          >
            {{ $t('Back to List') }}
          </b-button>
        </div>
        <div>
          <b-button
            variant="success"
            type="button"
            class="saveBtn font-medium-1 font-weight-bolder"
            :disabled="isCreateForm ? false : !isFormChanged"
            @click="submit"
          >
            {{ $t('SAVE') }}
          </b-button>
        </div>
      </div>
    </portal>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
  <div v-else>
    <skeleton />
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import { required } from '@/libs/validations/validations'
import { scrollToError } from '@core/utils/utils'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BRow, BCol, BForm, VBToggle, BFormCheckbox,
} from 'bootstrap-vue'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import config from '../assetConditionConfig'
import Skeleton from './Skeleton.vue'

export default {
  name: 'AssetConditionForm',
  components: {
    BFormCheckbox,
    BButton,
    BRow,
    BCol,
    BForm,
    ErrorAlert,
    ValidationObserver,
    Skeleton,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      error: {},
      loading: false,
      onSubmit: false,
    }
  },
  computed: {
    assetCondition() {
      return this.$store.state[this.MODULE_NAME].assetConditionForm
    },
    assetConditionClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].assetConditionFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.assetCondition) !== this.assetConditionClone
    },
    isUpdate() {
      return this.assetCondition.id
    },
  },
  methods: {
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.error = {}
      this.onSubmit = true

      this.$refs.assetConditionCreateVal.validate()
        .then(success => {
          if (success) {
            const data = this.assetCondition
            const assetConditionId = this.assetCondition.id
            const defaultAssetCondition = this.mappingFields(Object.keys(this.assetConditionFields), data, {
              id: assetConditionId,
              name: data.name,
              active: data.active ? Number(1) : Number(0),
            })
            this.sendNotification(this, { ...defaultAssetCondition, assetConditionId }, `${this.MODULE_NAME}/${this.assetCondition.id ? 'update' : 'create'}`)
              .then(res => {
                this.clear()
                // this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id)
                this.$router.replace({
                  name: 'inventory-asset-condition-list',
                  params: { id: res.data.data?.id },
                })
              })
              .catch(err => {
                this.error = err.response?.data
              }).finally(() => {
                this.onSubmit = false
              })
          } else {
            scrollToError(this, this.$refs.assetConditionCreateVal)
          }
        })
    },
    clear() {
      this.$refs.assetConditionCreateVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
    },
    cancel() {
      this.$router.push({ name: 'inventory-asset-condition-list' })
    },
    loader() {
      this.$refs.assetConditionCreateVal.reset()
      const smth = JSON.parse(this.assetConditionClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    getProps(fieldName) {
      const dataOF = {
        field: {
          ...this.assetConditionFields[fieldName],
        },
        name: fieldName,
      }
      // if (fieldName === 'state_id') {
      //   data.parentValue = this.brand.country_id?.id
      // }
      return dataOF
    },
  },
  setup() {
    const MODULE_NAME = 'asset-condition-taxonomies'
    const MODULE_NAME_CLONE = 'cloneData'
    const { assetConditionFields } = config()

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      assetConditionFields,
    }
  },
}
</script>
